export default () => {
  const { theme } = window;
  const { customer, emrENV, routes } = theme || {};

  const IS_STAGING = routes?.permanent_domain?.includes("staging");
  const EMR_BASE_URL = `https://us-west2-pet-emr-${emrENV}.cloudfunctions.net`;
  const isShelter = customer?.tags.some((tag) => tag.includes("Shelter:"));

  return {
    ...theme,
    isShelter,
    IS_STAGING,
    EMR_BASE_URL,
  };
};
